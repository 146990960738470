"use strict";

var AppMvp = AppMvp || {};
$(function () {
    var mvpUtils = {
        convertCultureToProperFormat: function (culture) {
            var formattedCulture = culture;

            // Information about what localizations are 4-digit or 2-digit 
            // and download links for the different jquery's datepicker 
            // localizations here: https://github.com/jquery/jquery-ui/tree/master/ui/i18n
            var fourDigitCulturesList = ["en-GB", "es"];
            if (fourDigitCulturesList.indexOf(culture) > -1) {
                return formattedCulture;
            }
            else {
                return fourDigitCulturesList[0];
            }
        },
        getParameterByName: function (name, url) {
            if (!url) {
                url = window.location.href;
            }

            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);

            if (!results) return null;
            if (!results[2]) return '';

            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        setValueToDatepickerFromQuerystring: function () {
            var dateValueFromQueryString = getParameterByName("date");
            var date = new Date(dateValueFromQueryString);
            var formattedDate = DateFormat.format.date(date, "E MMM d yyyy");

            document.getElementById("5fd33878-9fde-46df-9871-cc1ef0b2b626").setAttribute("value", formattedDate);
        }
    };

    return AppMvp.Utils = mvpUtils;
});