"use strict";

var AppMvp = AppMvp || {};
$(function () {
    var auditForms = {
        init: function(){
            // check if current url is from an audited form
            var isAudited = AppMvp.Utils.getParameterByName("audited");
            this.initDatePicker();

            if(!isAudited)
                return;

            this.auditFormReadOnlyIfAudited();
            AppMvp.Utils.setValueToDatepickerFromQuerystring();

            $("#contour_form_TechnicalAudit button[type*=submit]").hide();
            $("#edit-form").show();
        },
        initDatePicker: function () {
            var culture = document.documentElement.lang;
            var formattedCulture = AppMvp.Utils.convertCultureToProperFormat(culture);

            $(".formstechnicalauditdate input").datepicker($.datepicker.regional[formattedCulture]);
        },
        turnAuditFormEditable: function(){
            var isAudited = AppMvp.Utils.getParameterByName("audited");

            if(!isAudited)
                return;

            $("#contour_form_TechnicalAudit input").each(function(){
                $(this).prop("readonly", !$(this).prop("readonly"));
                $(this).prop("disabled", !$(this).prop("disabled"));
            });

            $("#edit-form").hide();
            $("#contour_form_TechnicalAudit button[type*=submit]").show();
        },
        auditFormReadOnlyIfAudited: function(){
            // Add the readonly property to the form's user inputs
            $("#contour_form_TechnicalAudit input").each(function(){
                $(this).prop("readonly", true);
                $(this).prop("disabled", true);
            });
        }

    };

    auditForms.init();
    return AppMvp.AuditForms = {
        turnAuditFormEditable: auditForms.turnAuditFormEditable()
    };
});