"use strict";

var AppMvp = AppMvp || {};
$(function () {
    var trainings = {
        init: function () {
            if ($("#trainings-list").length > 0) {
                this.initTrainingsListDatePicker();
                
                $(window).on("load", function () {
                    // wait for images to be loaded
                    setTimeout(function () { 
                        trainings.setCoursesTitleMinHeight();                                                
                        trainings.setCoursesContainerMinHeight();
                    }, 1000);
                });
            }

            if ($(".training-item__header").length > 0) {
                this.initTrainingsEventHandlers();
                this.setHeaderImageHeight();
            }
        },
        initTrainingsListDatePicker: function () {
            var culture = document.documentElement.lang;
            var formattedCulture = AppMvp.Utils.convertCultureToProperFormat(culture);

            $("#startDatepicker").datepicker($.datepicker.regional[formattedCulture]);
            $("#endDatepicker").datepicker($.datepicker.regional[formattedCulture]);
            $(".datepicker").after('<i class="fa fa-calendar iconCalendar"></i>');
        },
        initTrainingsEventHandlers: function () {
            $("#enrollment-button").click(function (event) {
                event.preventDefault();
                $("#enrollment-form").fadeToggle(500);
            });

            $("#evaluation-button").click(function (event) {
                event.preventDefault();
                $("#evaluation-form").fadeToggle(500);
            });

            $("#clear-filters-button").click(function (event) {
                event.preventDefault();
                $('input.searcher-checkbox').removeAttr('checked');
                document.getElementById('country').value = "all";
                document.getElementById('startDatepicker').value = "";
                document.getElementById('endDatepicker').value = "";
            });
        },
        setHeaderImageHeight: function () {
            var height = $(".training-item__header--details").outerHeight();
            $(".training-item__header--img-container img").css("height", height);
        },
        setCoursesContainerMinHeight: function () {
            var courseElements = $('.course-item');
            if(courseElements.length > 0) {
                courseElements.css("height", courseElements.height() + "px");
            }
        },
        setCoursesTitleMinHeight: function () {
            var courseTitleElements = $('.course-item h2');
            if(courseTitleElements.length > 0) {
                courseTitleElements.css("height", courseTitleElements.height() + "px");
            }
        }
    };

    trainings.init();
});