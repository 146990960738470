'use strict';

$(function () {
    /**
        GLOBAL FUNCTIONS
    **/
    
    $(document).ready(function () {
        $(window).keydown(function (event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });
    });
});
